<template>
<div>
   
  <div class="card" v-if="files">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcıya eklenen dosyalar</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{files.length}}</b> adet dosya</span>
      </h3>
    </div>
    <!--end::Header-->

      <div class="card-body">
        <UserFilesList :files="files" :profile="profile"  />   
      </div>
     
  </div>
</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserFilesList from '@/components/modals/forms/UserFilesList.vue'
import api from '@/core/services/ApiService'

export default {
  
  name: "Dosyalar",
  props:['profile'],
  components:{UserFilesList},
  data(){
      return {
        files:[],
      }
  },
  
  

  methods:{

      getNotes(){
         if(this.profile){
             api.get('/File/User/List/'+this.profile.userId).then((res)=>{
                this.files = res.data;                
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Aktiviteler", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);
      this.getNotes();
    
  }
  
}
</script>
