<template>
  <div>
    <a v-if="isAdmin" href="#new_package" data-bs-target="#new_package" class="btn btn-success btn-sm mb-10" data-bs-toggle="modal">Yeni dosya ekle</a>
    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
      <thead>
          <tr class="fw-bolder">
              <th>Başlık</th>
              <th>Açıklama</th>
              <th>Dosya adı</th>
              <th>Dosya tipi</th>
              <th>Dosya boyutu</th>
              <th>İşlem</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(item, i) in files" :key="i">
              <td>{{item.fileTitle}}</td>                       
              <td>{{item.fileDesc}}</td>                       
              <td>{{item.fileName}}</td>                       
              <td>{{item.fileType}}</td>                       
              <td>{{item.fileSize}}</td>                       
              <td>
                <div v-if="isAdmin">
                    <a @click.prevent="DeleteUserFile(item)" class="btn btn-sm btn-danger" href="">Kaldır</a>
                </div>
              </td>
          </tr>
      </tbody>
    </table>


    <div class="modal fade" ref="modal" tabindex="-1" id="new_package">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span class="text-danger fw-bolder">
            Paket seçin
          </span>
          </h5>

        <!--begin::Close-->
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body" v-if="availableFiles" >
         
         <div class="row mb-2">
            <div class="col-lg-3 col-label fw-bold">Danışman seçin</div>
            <div class="col-lg-9">
                <input type="text" name="search" @keyup="search" v-model="consultant" class="form-control">
                <div class="text-muted">Dosyasını eklemek istediğiniz danışmanın adının bir kaç harfini giriniz.</div>
            </div>
         </div>
         <div class="row mb-2" v-for="(fileItem) in consultantFiles" :key="fileItem.fileId">
           <label class="pe-10 ps-10 pb-2 pt-2 hover-bg-primary d-flex align-items-center border border-radius">
            <div>
                <div class="text-muted">
                    <span class="fw-bold me-4">{{ fileItem.consultant }} </span>
                    <span class="fst-italic">{{ fileItem.fileTitle }}</span>
                    
                </div>
                
                <div>
                    <input type="radio" class="me-5" name="fileId" v-model="selectedFile" :value="fileItem">
                    <span>{{fileItem.fileName}}</span>
                </div>
            </div>
             
           </label>
         </div>
      </div>

      <div class="modal-footer">
        <button type="button" data-bs-dismiss="modal" class="btn btn-light">Kapat</button>
        <button type="submit" @click.prevent="addFileToUser()" class="btn btn-primary" v-if="selectedFile">
          Kaydet
        </button>
      </div>
    </div>
  </div>
</div>
 
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import api from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'

export default {
    name:'UserFilesList',
    props:['files','profile'],
    data(){
        return {
            isAdmin:false,
            availableFiles:null,
            consultant:null,
            consultantFiles:[],
            selectedFile:null
        }
    },

    methods:{

        addFileToUser(){
           Swal.fire({
            icon:'warning',
            title:'Kullanıcıya dosya ekleme',
            text:this.selectedFile.fileTitle+' başlıklı dosyayı, '+this.profile.name+ " "+this.profile.surname+" isimli kullanıcıya eklemek istediğinize emin misiniz?",
            showCancelButton:true,
            cancelButtonText:'Vazgeç',
            confirmButtonText:'Evet'
           }).then((res)=>{
            if(res.isConfirmed){
                api.get('/File/User/Add/'+this.profile.userId+'/'+this.selectedFile.fileId).then(res=>{
                    document.location.reload();
                })
            }
           })
        },

        search(form){

            if(form.target.value.length>=3){
                this.consultantFiles = (this.availableFiles.filter(item => {                
                    return item.consultant.toUpperCase().indexOf(form.target.value.toUpperCase())>-1;
                } 
                ))
            }else{
                this.consultantFiles = [];
                this.selectedFile=null
            }

            
        },
      

      DeleteUserFile(item){
        Swal.fire({
          title:'Emin misiniz?',
          icon:'warning',
          text:item.fileTitle+' başlıklı dosyayı kullanıcıdan kaldırmak istediğinize emin misiniz?',
          showCancelButton:true,
          cancelButtonText:'Vazgeç',
        }).then((res)=>{
          if(res.isConfirmed){
            api.get('/File/User/Remove/'+this.profile.userId+'/'+item.fileId).then((res)=>{
              Swal.fire({
                title:'Bilgi',
                icon:'success',
                text:'Dosya kullanıcıdan silindi.',
              }).then(()=>{
                document.location.reload();
              });
            })
          }
        });
      },

     


    },

    mounted(){
      this.isAdmin = JwtService.isAdmin();
      api.get('/File/List').then((res)=>{
        this.availableFiles = res.data;
        

        

        
      })
    }
}
</script>

<style>

</style>